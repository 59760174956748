<template>
  <van-popup v-model="show" position="bottom" round>
    <van-cell-group inset>
      <van-cell
        v-for="(name, locale) in locales"
        :key="locale"
        :title="name"
        :value="locale"
        @click="modifyLocale(locale)"
      />
    </van-cell-group>
  </van-popup>
</template>

<script>
import { putLocalStorageBaseCurrency } from '@/views/business/libs/kit/baseCurrency.js'

const locales = {
  zh_CN: '简体中文',
  en_US: 'English'
};

export default {
  data() {
    return {
      show: false,
      locales
    };
  },
  methods: {
    openSelector() {
      this.show = true;
    },
    modifyLocale(locale) {
      this.$store.commit('i18n/modifyLocale', locale);
      this.show = false;
      putLocalStorageBaseCurrency(this);
    }
  }
};
</script>
