<template>
  <div style="margin-top: -16px;height:100%;overflow-y:auto;display:flex;flex-direction:column;">
    <div class="home_top">
      <h2>
        <span>{{ $t('home.中建材海外自助平台') }}</span>
        <!-- <img src="../../images/bgc/close.png"
             alt=""
             style="width:60px;height:22px;"> -->
        <van-button round style="position: absolute; right: 5px;" @click="$refs.langSelector.openSelector()">
          {{ $t('components.语言') }}
        </van-button>
        <langSelector ref="langSelector" />
      </h2>
      <div>
        <div class="top_left">
          <h2><span>{{ ruleForm.psnName }}</span>,<span>{{ greetings }}</span></h2>
          <div>
            <i class="iconfont icon-zhiwei"></i>
            <div v-if="ruleForm.deptName || ruleForm.jobName">
              <span>{{ ruleForm.deptName }}</span>
              <span style="display: inline-block" v-if="false">{{ ruleForm.jobName }}</span>
            </div>
            <div v-else>--</div>
          </div>
        </div>
        <div class="top_right" @click="logOut">
          <img :src="ruleForm.photo" v-if="ruleForm.photo" />
          <img src="../../images/home/defaultHead.png"
               alt=""
               v-else>
        </div>
      </div>
    </div>
    <div class="home_center">
      <div>
        <div @click="goRouter('workFlow/workFlowList',{type:'waitfor'})">
          <p>
            <i class="iconfont icon-daiban"></i>
          </p>
          <div>
            <span>{{ $t('home.待办') }}</span>
            <p>{{ queryItem.toDoSize }}</p>
          </div>
        </div>
        <div @click="goRouter('workFlow/workFlowList',{type:'transated'})">
          <p>
            <i class="iconfont icon-yiban"></i>
          </p>
          <div>
            <span>{{ $t('home.已办') }}</span>
            <p>{{ queryItem.haveSize }}</p>
          </div>
        </div>
      </div>
      <div>
        <div @click="goRouter('workFlow/workFlowList',{type:'read'})">
          <p>
            <i class="iconfont icon-daiyue"></i>
          </p>
          <div>
            <span>{{ $t('home.待阅') }}</span>
            <p>{{ queryItem.unReadSize }}</p>
          </div>
        </div>
        <div @click="goRouter('workFlow/workFlowList',{type:'readed'})">
          <p>
            <i class="iconfont icon-yiyue"></i>
          </p>
          <div>
            <span>{{ $t('home.已阅') }}</span>
            <p>{{ queryItem.readedSize }}</p>
          </div>
        </div>
      </div>
    </div>
    <div v-if="false" class="home_middle">
      <p>{{ $t('home.个人考勤') }}</p>
      <div @click="$router.push('tbm/selfService/selfServiceList')">{{ $t('home.点此查看个人考勤') }}</div>
    </div>
    <div v-if="false" class="home_bottom">
      <div @click="goRouter('tbm/applyHoliday/applyHolidayList')">
        <i class="iconfont icon-qingxiujiashenqing"></i>
        <span>{{ $t('home.请休假申请') }}</span>
      </div>
      <div @click="goRouter('tbm/applyOfficeout/applyOfficeoutList')">
        <i class="iconfont icon-gongchushenqing"></i>
        <span>{{ $t('home.公出申请') }}</span>
      </div>
      <div @click="goRouter('tbm/overtimeApplication/overtimeApplicationList')">
        <i class="iconfont icon-jiabanshenqing"></i>
        <span>{{ $t('home.加班申请') }}</span>
      </div>
      <div @click="goRouter('tbm/applyUnusual/applyUnusualList')">
        <i class="iconfont icon-yichangshenqing"></i>
        <span>{{ $t('home.异常申请') }}</span>
      </div>
      <div @click="goRouter('hrhi/informationEdit')">
        <i class="iconfont icon-yichangshenqing"></i>
        <span>{{ $t('home.个人信息') }}</span>
      </div>
      <div @click="goRouter('tbm/employeePassword')">
        <i class="iconfont icon-yichangshenqing"></i>
        <span>{{ $t('home.工资信息') }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { Dialog } from 'vant';
import langSelector from '@/components/lang/langSelector';

import { putLocalStorageBaseCurrency } from '@/views/business/libs/kit/baseCurrency.js'

export default {
    components: {
        [Dialog.Component.name]: Dialog.Component,
        langSelector
    },
  name: "Home",
  data() {
    return {
      greetings: "",
      ruleForm: {
        photo: "",
        psnName: "",
        deptName: "",
        jobName: ""
      },
      queryItem: {
        haveSize: 0,
        readedSize: 0,
        toDoSize: 0,
        unReadSize: 0
      },
      data: [
        {
          text: "toDoSize",
          url: "/microarch/activiti/task/toDoList/",
          orderBy: "createTime DESC",
          where: []
        },
        {
          text: "haveSize",
          url: "/microarch/activiti/task/haveDoneList/",
          orderBy: "endTime DESC",
          where: []
        },
        {
          text: "unReadSize",
          url: "/microarch/activiti/task/encyclicList/",
          orderBy: "cdate DESC",
          where: [
            {
              "field": "speople",
              "opt": "like",
              "value": sessionStorage.getItem("userName"),
              "assemble": "and"
            },
            {
              "field": "sdate",
              "opt": "null",
              "assemble": "and"
            }
          ]
        },
        {
          text: "readedSize",
          url: "/microarch/activiti/task/encyclicReadedList/",
          orderBy: "cdate DESC",
          where: [
            {
              "field": "speople",
              "opt": "like",
              "value": sessionStorage.getItem("userName"),
              "assemble": "and"
            },
            {
              "field": "sdate",
              "opt": "notnull",
              "assemble": "and"
            }
          ]
        }
      ]
    };
  },
  methods: {
      logOut () {
          Dialog.confirm({
              message: '是否退出登录?',
          }).then(() => {
              this.setCookie("","");
              this.$router.push({
                  name: 'Login'
              });
          }) .catch(() => {
              // on cancel
          });
      },
      setCookie(name, value) {
          window.document.cookie = "userName" + "=" + name + ";path=/;expires=";
          window.document.cookie = "userPwd" + "=" + value + ";path=/;expires=";
      },
    //获取个人信息
    getRuleform() {
      //   this.$myHttp({
      //     method: 'post',
      //     url: '/hrhi/empinfo/initEmpInfo',
      //   }).then(res => {
      //     if (res.data.entity) {
      //       this.ruleForm = res.data.entity;
      //     }
      //   });
      let userInfo = JSON.parse(localStorage.getItem("userInfo"));
      this.ruleForm.psnName = userInfo.cname;
      this.ruleForm.deptName = userInfo.deptName;
    },
    //待办已办待阅已阅
    showCrouselData() {
      this.$myHttp({
        method: "post",
        url: '/microarch/activiti/task/getIndexMsg/in/' + localStorage.getItem('usernames')
      }).then(res => {
        if (res.data) {
          this.queryItem = res.data;
          sessionStorage.setItem('app_list_count', JSON.stringify(this.queryItem));
        }
      });
    },
    showCrouselData2() {
      this.data.map(key => {
        this.getInit(key).then((res) => {
          if (res.status == 200) {
            let dataList = res.data.rows;
            let leaveType = this.TbmCons.leaveType;
            dataList.map(item => {
              if (/^tbm\/overtimeApplication./.test(item.businessUrl)
                ||
                /^tbm\/applyOfficeout./.test(item.businessUrl)
                ||
                /^tbm\/applyHoliday./.test(item.businessUrl)
                ||
                /^tbm\/applyUnusual./.test(item.businessUrl)) {
                this.queryItem[key.text] += 1;
              }
            });
          }
        });
      });
    },
    getInit(key) {
      return new Promise(resolve => {
        this.$myHttp({
          method: "POST",
          url: key.url + sessionStorage.getItem("userName"),
          data: {
            pageNum: this.pageNum,
            pageSize: 1000,
            orderBy: key.orderBy,
            where: key.where
          }
        }).then(res => {
          resolve(res);
        });
      });
    },
    //获取问候语
    getHours() {
      var now = new Date();
      var hour = now.getHours();
      if (hour >= 5 && hour < 8) {
        this.greetings = "早上好";
      } else if (hour >= 8 && hour < 12) {
        this.greetings = "上午好";
      } else if (hour >= 12 && hour < 18) {
        this.greetings = "下午好";
      } else if (hour >= 18 || hour < 5) {
        this.greetings = "晚上好";
      }
    },
    goRouter(name, query = {}) {
      // console.log(name)
      // return
      this.$router.push({
        name,
        query
      });
    },
    setCompanyBaseCurrency () {
      putLocalStorageBaseCurrency(this);
    }
  },
  mounted() {
    this.setCompanyBaseCurrency();
  },
  created() {
    //获取个人信息
    this.getRuleform();
    //待办已办
    // this.showCrouselData();
    this.showCrouselData();
    //获取问候语
    this.getHours();
  }
};
</script>

<style scoped>
.home_top {
  height: 15%;
  min-height: 144px;
  background: rgba(67, 120, 190, 1);
  border-radius: 0px 0px 10px 10px;
  padding: 24px 10px 0 10px;
}

.home_top > h2 {
  font-size: 22px;
  font-family: Sun Yat-sen Hsingshu;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  line-height: 37px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.home_top > h2 > span {
  flex: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.home_top > div {
  height: 74px;
  padding: 10px 17px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 26px 0px rgba(0, 38, 76, 0.17);
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.home_top .top_left h2 {
  font-size: 22px;
  font-family: PingFangSC;
  font-weight: 600;
  color: rgba(67, 120, 190, 1);
  margin-top: 0px;
}

.home_top .top_left > div {
  display: flex;
  align-items: center;
}

.home_top .top_left > div i {
  width: 17px;
  height: 17px;
  font-size: 17px;
  margin-right: 10px;
}

.home_top .top_left > div div {
  font-size: 14px;
  font-family: PingFangSC;
  font-weight: 400;
  color: rgba(67, 120, 190, 1);
}

.top_right {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.top_right img {
  width: 60px;
  height: 60px;
}

.home_center {
  height: 28%;
  display: flex;
  flex-direction: column;
  padding: 10px 10px 0 10px;
  margin-top: 20px;
}

.home_center > div {
  flex: 1;
  display: flex;
}

.home_center > div > div {
  flex: 1;
  background: #fff;
  display: flex;
  align-items: center;
  padding: 10px 16px;
  margin-bottom: 10px;
  box-shadow: 0px 2px 16px 0px rgba(0, 38, 76, 0.17);
  border-radius: 6px;
}

.home_center > div > div > p {
  width: 43px;
  height: 43px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  margin-right: 16px;
}

.home_center > div > div > p i {
  width: 23px;
  height: 23px;
  font-size: 23px;
  color: #fff;
}

.home_center > div:nth-child(1) > div > p:nth-child(1) {
  background: rgba(254, 164, 1, 1);
}

.home_center > div:nth-child(1) > div > p:nth-child(2) {
  background: rgba(48, 170, 81, 1);
}

.home_center > div:nth-child(2) > div > p:nth-child(1) {
  background: rgba(63, 133, 226, 1);
}

.home_center > div:nth-child(2) > div > p:nth-child(2) {
  background: rgba(0, 177, 255, 1);
}

.home_center > div > div:nth-child(1) {
  margin-right: 10px;
}

.home_center > div > div > div span {
  font-size: 15px;
  font-family: PingFangSC;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
}

.home_center > div > div > div p {
  font-size: 18px;
  font-family: PingFangSC;
  font-weight: 600;
  color: rgba(244, 89, 87, 1);
  margin-top: 7px;
}

.home_middle {
  height: 15%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-image: url('../../images/bgc/kaoqin-bg.png');
  background-size: 100% 100%;
  padding: 10px 28px;
}

.home_middle p {
  font-size: 20px;
  font-family: PingFangSC;
  font-weight: 400;
  color: rgba(0, 87, 176, 1);
  margin-bottom: 9px;
}

.home_middle div {
  width: 150px;
  padding: 8px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 7px 0px rgba(0, 38, 76, 0.1);
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: PingFangSC;
  font-weight: 400;
  color: rgba(0, 101, 209, 1);
}

.home_bottom {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  background: #fff;
  padding: 10px 10px 50px 10px;
}

.home_bottom div {
  width: 49.8%;
  min-height: 105px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(247, 252, 255, 1);
  border-bottom: 1px solid rgba(238, 238, 238, 1);
}

.home_bottom div:nth-child(1),
.home_bottom div:nth-child(2) {
  border-top: 1px solid rgba(238, 238, 238, 1);
}

.home_bottom div:nth-child(1),
.home_bottom div:nth-child(3) {
  border-right: 1px solid rgba(238, 238, 238, 1);
}

.home_bottom div i {
  width: 35px;
  height: 35px;
  font-size: 35px;
  color: #3c90ff;
  margin-bottom: 20px;
}

.home_bottom div span {
  font-size: 16px;
  font-family: PingFangSC;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
}
</style>
