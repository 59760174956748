import axios from "axios";
import i18n from "../../../../store/modules/i18n";

// 根据公司id和语言查询本位币
export function putLocalStorageBaseCurrency (self) {
    const url = '/microarch/masterdata/basecurrencyset/getBaseCurrency';
    const { companyId } = JSON.parse(localStorage.getItem('userInfo'));
    const lang = i18n.locale;

    axios.post(url, { entity: { orgId: companyId, lang: lang } }).then(res => {
        let result = res.data.entity;
        let currencySet = res.data.entity.filter(it => it.orgId === companyId);
        const companyCurrencySet = {
            currencyType: currencySet[0].currencyType,
            currencyCode: currencySet[0].baseCurrency,
            currencyName: currencySet[0].baseCurrencyName
        };
        localStorage.setItem('companyBaseCurrency', JSON.stringify(companyCurrencySet));
        localStorage.setItem('companyBaseCurrencySet', JSON.stringify(result));
        self.$bus.emit('LocalCurrency-Changed');
    });
}
